<template>
  <SectionY :padding="data.padding?.value">
    <ContainerX is-max>
      <div class="mx-auto max-w-component">
        <div class="grid grid-cols-1 gap-16 sm:gap-32 md:grid-cols-2 xl:gap-52">
          <!-- TEXT -->
          <div>
            <h2
              class="default-component-title mb-6 text-left sm:mb-8"
              v-html="$replaceHtmlTags(data.heading || defaults.heading)"
            />
            <div
              class="text max-w-text text-sm sm:text-md"
              v-html="data.text || defaults.text"
            />
          </div>
          <!-- FORM -->
          <Form
            ref="form"
            v-slot="{ errors }"
            novalidate
            class=""
            @submit="submit"
          >
            <template v-for="(field, index) in fields" :key="field.handle">
              <InViewAnimate :delay="200 * index || 0">
                <InputField
                  :field="field"
                  :rules="field.rules"
                  :label="field.label"
                  :error="errors[field.handle]"
                  class="mb-2 sm:mb-3"
                  :value="form[field.handle]"
                  @field-input="form[field.handle] = $event"
                />
              </InViewAnimate>
            </template>
            <InViewAnimate :delay="200 * fields.length">
              <AppButton
                type="submit"
                :class="{ 'pointer-events-none': Object.keys(errors).length }"
                class="my-12 md:float-right"
                :disabled="Object.keys(errors).length"
              >
                {{ data.link_text || defaults.link_text }}
              </AppButton>
            </InViewAnimate>
          </Form>
        </div>
      </div>
    </ContainerX>
  </SectionY>
</template>

<script>
import { Form } from 'vee-validate'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
export default {
  components: {
    Form,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const main = useMainStore()
    const { teaser_registration_defaults } = storeToRefs(main)
    return {
      defaults: teaser_registration_defaults,
    }
  },

  data() {
    return {
      fields: [
        {
          handle: 'first_name',
          label: 'First Name',
          placeholder: 'First Name',
          rules: 'required',
          config: {
            input_type: 'text',
          },
        },
        {
          handle: 'last_name',
          label: 'Last Name',
          placeholder: 'Last Name',
          rules: 'required',
          config: {
            input_type: 'text',
          },
        },
        {
          handle: 'email',
          label: 'E-Mail',
          placeholder: 'E-Mail',
          rules: 'required|email',
          config: {
            input_type: 'email',
          },
        },
      ],

      form: {
        first_name: '',
        last_name: '',
        email: '',
      },
    }
  },

  methods: {
    submit() {
      if (!this.data.link && !this.defaults.link) {
        console.error('No link provided for Teaser Registration')
        return
      }

      this.$router.push({
        path: this.data.link || this.defaults.link,
        query: {
          teaser_registration: encodeURI(JSON.stringify(this.form)),
        },
      })
    },
  },
}
</script>
